import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    ResetSerialNumber,
    SetDataloggerCode,
    SetDataloggerSerialNumber,
    SetErrorPopup,
    SetSerialNumber,
    ToggleErrorPopup,
    ToggleIsAuthCode,
    ToggleIsPreloader,
} from '../actions/create-devices.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { TranslateService } from '@ngx-translate/core';

export interface CreateDevicesStateModel {
    data: any;
    serialNumber: string;
    isErrorPopup: boolean;
    errorMessage: string;
    isAuthCode: boolean;
    status: HTTP_STATUS;
    isPreloader: boolean;
}

const CREATE_DEVICES_TOKEN = new StateToken<CreateDevicesStateModel>('createDevices');

@State<CreateDevicesStateModel>({
    name: CREATE_DEVICES_TOKEN,
    defaults: {
        data: [],
        serialNumber: null,
        isErrorPopup: false,
        errorMessage: null,
        isAuthCode: false,
        status: null,
        isPreloader: false,
    },
})
@Injectable()
export class CreateDevicesState {
    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
    ) {}

    @Selector()
    static getIsErrorPopup(state: CreateDevicesStateModel): boolean {
        return state.isErrorPopup;
    }

    @Selector()
    static getErrorMessage(state: CreateDevicesStateModel): string {
        return state.errorMessage;
    }

    @Selector()
    static getIsAuthCode(state: CreateDevicesStateModel): boolean {
        return state.isAuthCode;
    }

    @Selector()
    static getDeviceStatus(state: CreateDevicesStateModel): HTTP_STATUS {
        return state.status;
    }

    @Selector()
    static getSerialNumber(state: CreateDevicesStateModel): string {
        return state.serialNumber;
    }

    @Selector()
    static getIsPreloader(state: CreateDevicesStateModel): boolean {
        return state.isPreloader;
    }

    @Action(SetDataloggerSerialNumber)
    async setDataloggerSerialNumber(ctx: StateContext<CreateDevicesStateModel>, payload: SetDataloggerSerialNumber): Promise<any> {
        // const state = ctx.getState();
        // ctx.setState({
        //     ...state,
        //     isAuthCode: true,
        // });
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post('/api/datalogger-settings', {
                internalId: state.serialNumber,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.DATA_ALREADY_EXISTS) {
            ctx.setState({
                ...state,
                isErrorPopup: true,
                errorMessage: 'createDevices.errorMessages.alreadyExist',
            });

            return;
        }

        if (result && result.status === HTTP_STATUS.MANY_TRY) {
            const tryMessage = await this.translateService
                .get('createDevices.errorMessages.tryError', { time: new Date(+result.data?.waitTo).getMinutes() })
                .toPromise();
            ctx.setState({
                ...state,
                isErrorPopup: true,
                status: result.status,
                errorMessage: tryMessage,
            });
            return;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                isAuthCode: true,
            });
        } else {
            ctx.setState({
                ...state,
                serialNumber: null,
                isErrorPopup: true,
                status: result.status,
                errorMessage: 'createDevices.errorMessages.errorMessage',
            });
        }
    }

    @Action(ResetSerialNumber)
    resetSerialNumber(ctx: StateContext<CreateDevicesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            serialNumber: null,
        });
    }

    @Action(ToggleErrorPopup)
    toggleErrorPopup(ctx: StateContext<CreateDevicesStateModel>, payload: ToggleErrorPopup): void {
        const state = ctx.getState();

        if (state.status === HTTP_STATUS.MANY_TRY) {
            ctx.setState({
                ...state,
                isErrorPopup: payload.toggle,
                isAuthCode: false,
                errorMessage: null,
            });
            return;
        }

        ctx.setState({
            ...state,
            isErrorPopup: payload.toggle,
            errorMessage: null,
        });
    }

    @Action(ToggleIsAuthCode)
    toggleIsAuthCode(ctx: StateContext<CreateDevicesStateModel>, payload: ToggleIsAuthCode): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isAuthCode: payload.toggle,
        });
    }

    @Action(ToggleIsPreloader)
    toggleIsPreloader(ctx: StateContext<CreateDevicesStateModel>, payload: ToggleIsPreloader): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isPreloader: payload.toggle,
        });
    }

    @Action(SetSerialNumber)
    setSerialNumber(ctx: StateContext<CreateDevicesStateModel>, payload: SetSerialNumber): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            serialNumber: payload.serialNumber,
        });
    }

    @Action(SetErrorPopup)
    setErrorPopup(ctx: StateContext<CreateDevicesStateModel>, payload: SetErrorPopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isErrorPopup: payload.isToggle,
            errorMessage: payload.message,
        });
    }

    @Action(SetDataloggerCode)
    async setDataloggerCode(ctx: StateContext<CreateDevicesStateModel>, payload: SetDataloggerCode): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .put('/api/datalogger-settings', {
                internalId: state.serialNumber,
                code: payload.code,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && +result.data?.data?.countAttempts === 0 && result.status === HTTP_STATUS.INVALID_PASSWORD) {
            const tryMessage = await this.translateService.get('createDevices.errorMessages.tryError', { time: 10 }).toPromise();
            ctx.setState({
                ...state,
                isErrorPopup: true,
                status: result.status,
                isAuthCode: false,
                errorMessage: tryMessage,
            });
            return;
        }

        if (result && result.status === HTTP_STATUS.INVALID_PASSWORD) {
            const tryMessage = await this.translateService
                .get('createDevices.errorMessages.tryCountError', { count: +result.data?.data?.countAttempts })
                .toPromise();
            ctx.setState({
                ...state,
                isErrorPopup: true,
                status: result.status,
                errorMessage: tryMessage,
            });
            return;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                isErrorPopup: true,
                status: result.status,
                isPreloader: true,
                errorMessage: 'createDevices.errorMessages.success',
            });
        } else {
            ctx.setState({
                ...state,
                isErrorPopup: true,
                status: result.status,
                errorMessage: 'createDevices.errorMessages.error',
            });
        }
    }
}
